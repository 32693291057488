import { template as template_8ef1ed38821a4a1199d136d30864d0f6 } from "@ember/template-compiler";
const SidebarSectionMessage = template_8ef1ed38821a4a1199d136d30864d0f6(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
