import { template as template_4617effb3cd44f19b8e9f782d3c49964 } from "@ember/template-compiler";
const WelcomeHeader = template_4617effb3cd44f19b8e9f782d3c49964(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
